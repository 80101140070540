<template>
  <div class="home">
    <section class="hero">
      <div class="container-fluid">
        <div class="row py-5">
          <div class="col-sm-10 mx-auto">
            <div class="row">
              <div class="col-sm-8">
                <img
                  src="@/assets/img/landing/Top/Vector Smart Object.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-sm-8 py-3">
                <img
                  src="@/assets/img/landing/Top/highlighted txt.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-sm-4 text-center py-3">
                <img
                  src="@/assets/img/landing/Top/left icon.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-sm-6 mx-auto text-center py-3">
                <img
                  src="@/assets/img/landing/Top/Surface Engineering for Sustainability.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <marquee width="100%" direction="left" class="bg-primary text-light py-3">
        <span class="mx-5 px-5 fs-3">
          Sign up for the conference now to enjoy discounted rates! Online registration ends on
          <span class="fw-bold">30<sup>th</sup> August 2023</span></span
        >
      </marquee>
    </section>

    <section class="timeline">
      <div class="container-fluid">
        <div class="row py-5">
          <div class="col-sm-10 mx-auto text-center">
            <img
              src="@/assets/img/landing/Timeline/timeline 1005.png"
              class="img-fluid"
              alt=""
            />
            <div
              class="py-4 text-center my-3"
              style="width: 100%; background: #eee"
            >
              <h3>CLICK HERE TO SUBMIT YOUR ABSTRACT</h3>
              <!-- <img
                src="@/assets/img/landing/Timeline/Submit button.png"
                class="img-fluid"
                alt=""
              /> -->
              <a
                class="btn btn-warning text-primary fw-bold fs-2 px-5"
                href="/abstract"
              >
                SUBMIT AN ABSTRACT
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="topics">
      <div class="container-fluid">
        <div class="row py-5">
          <div class="col-sm-10 mx-auto">
            <!-- <img
              src="@/assets/img/landing/Timeline/timeline.png"
              class="img-fluid"
              alt=""
            /> -->
            <h2 class="text-center text-primary headings">TOPICS</h2>
            <div class="row">
              <div class="col-md-6 text-center py-4">
                <img
                  src="@/assets/img/landing/Topics/1.png"
                  class="img-fluid"
                  alt=""
                />
                <p
                  class="mt-2 mb-0 fs-5 fw-semibold text-primary text-uppercase"
                >
                  Advanced techniques for surface engineering towards enhanced
                  performance
                </p>
                <!-- <p class="mb-0 fs-5 fw-semibold text-primary text-uppercase">
                  surface engineering towards​
                </p> -->
                <!-- <p class="mb-0 fs-5 fw-semibold text-primary text-uppercase">
                  enhanced performance​
                </p> -->
                <div class="py-2 d-flex justify-content-center">
                  <ul class="text-start">
                    <li>New and advanced surface engineering technologies</li>
                    <li>
                      Surface engineering in industrial practice (Shot Peening,
                      Hammer Peening, Deep Cold Rolling, Ultrasonic Shot
                      Peening)
                    </li>
                    <li>
                      Laser surface treatment (Laser Shock Peening, Finishing
                      and Texturing)
                    </li>
                    <li>
                      Surface engineering / post processing for additively
                      manufactured components
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 text-center py-4">
                <img
                  src="@/assets/img/landing/Topics/2.png"
                  class="img-fluid"
                  alt=""
                />
                <p class="mt-2 mb-0 fs-5 fw-semibold text-primary">
                  SURFACE AND SUB-SURFACE
                </p>
                <p class="mb-0 fs-5 fw-semibold text-primary">
                  CHARACTERISATION (IN-SITU AND EX-SITU)
                </p>

                <div class="py-2 text-start">
                  <ul>
                    <li>Evaluation of surfaces properties: AFM, SEM, TEM​</li>
                    <li>
                      Erosion & corrosion assessment (cavitation erosion, wear,
                      erosion corrosion)
                    </li>
                    <li>Quality and consistency of surface treatments​</li>
                    <li>
                      Mechanical properties (complex geometry, thin film,
                      small-scale mechanical property, in-situ measurement) ​
                    </li>
                    <li>Characterisation of coatings and interfaces​</li>
                  </ul>
                </div>
              </div>

              <div class="col-md-6 text-center py-4">
                <img
                  src="@/assets/img/landing/Topics/3.png"
                  class="img-fluid"
                  alt=""
                />
                <p class="mt-2 mb-0 fs-5 fw-semibold text-primary">
                  SIMULATION AND MODELLING
                </p>
                <p class="mb-0 fs-5 fw-semibold text-primary">
                  OF SURFACE INTEGRITY
                </p>
                <div class="py-2 text-start">
                  <ul>
                    <li>Process simulation</li>
                    <li>Machine learning in surface engineering</li>
                    <li>Automation in surface engineering</li>
                    <li>Damage mechanics at varied length scales</li>
                    <li>Residual stresses and their relaxation</li>
                    <li>Structure-property correlation</li>
                  </ul>
                </div>
              </div>

              <div class="col-md-6 text-center py-4">
                <img
                  src="@/assets/img/landing/Topics/4.png"
                  class="img-fluid"
                  alt=""
                />
                <p
                  class="mt-2 mb-0 fs-5 fw-semibold text-primary text-uppercase"
                >
                  Non-Destructive Inspection and
                </p>
                <p class="mb-0 fs-5 fw-semibold text-primary text-uppercase">
                  In-Line Monitoring for Quality Control
                </p>
                <div class="py-2 text-start">
                  <ul>
                    <li>Manufacturing</li>
                    <li>Production</li>
                    <li>Repair and maintenance​</li>
                  </ul>
                </div>
              </div>

              <div class="col-md-6 text-center py-4">
                <img
                  src="@/assets/img/landing/Topics/5.png"
                  class="img-fluid"
                  alt=""
                />
                <p
                  class="mt-2 mb-0 fs-5 fw-semibold text-primary text-uppercase"
                >
                  Advanced Coating Materials:
                </p>
                <p class="mb-0 fs-5 fw-semibold text-primary text-uppercase">
                  Design, Synthesis, and
                </p>
                <p class="mb-0 fs-5 fw-semibold text-primary text-uppercase">
                  Industry Applications
                </p>

                <div class="py-2 text-start">
                  <ul>
                    <li>New coating materials: High entropy alloys</li>
                    <li>
                      Protective and functional coatings: anti-corrosion
                      coatings, etc
                    </li>
                    <li>Polymer and paint coatings</li>
                    <li>
                      Conversion Coating: Carburizing, Nitriding,
                      electro-plating, and Anodizing
                    </li>
                    <li>Chemical and Physical Vapour Deposition</li>
                    <li>
                      Additive manufactured coatings: Thermal spraying, cold
                      spraying
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-6 text-center py-4">
                <img
                  src="@/assets/img/landing/Topics/6.png"
                  class="img-fluid"
                  alt=""
                />
                <p
                  class="mt-2 mb-0 fs-5 fw-semibold text-primary text-uppercase"
                >
                  Emerging Trends in Surface Engineering:
                </p>

                <p class="mb-0 fs-5 fw-semibold text-primary text-uppercase">
                  Green Technologies Towards
                </p>
                <p class="mb-0 fs-5 fw-semibold text-primary text-uppercase">
                  Resilience and Sustainability
                </p>
                <div class="py-2 text-start">
                  <ul>
                    <li>Hydrogen economy</li>
                    <li>
                      Clean energies: Low carbon emission, net zero processes
                    </li>
                    <li>Surface engineering in e-mobility</li>
                    <li>Self-cleaning technology (hydrophobic, oleophobic)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="symposium">
      <div class="container-fluid">
        <div class="row py-5">
          <div class="col-sm-10 mx-auto">
            <h2 class="text-center text-primary text-uppercase headings">
              symposiums
            </h2>
            <div class="row py-4">
              <div
                class="col-sm-3 text-center d-flex justify-content-center align-items-center"
              >
                <img
                  src="@/assets/img/landing/Symposium.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-sm-9">
                <p class="text-primary fs-4">
                  International Symposium on cold spray​
                </p>
                <ul>
                  <li>
                    Cold spray metal, ceramic and metal matrix composite
                    coatings
                  </li>
                  <li>Materials development of new coatings</li>
                  <li>Cold spray process optimization and post-processing</li>
                  <li>Mechanism of impact bonding</li>
                  <li>Characterization and testing of cold spray coatings</li>
                  <li>Modelling and numerical simulation</li>
                  <li>Cold spray additive manufacturing</li>
                  <li>
                    Cold spray coatings for aerospace, automotive and marine
                    industries
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="about">
      <div class="container-fluid" style="background: #eee">
        <div class="row py-5">
          <div class="col-sm-10 mx-auto">
            <h2 class="text-center text-primary headings">
              ABOUT THE CONFERENCE
            </h2>
            <p class="mb-0">
              First held in 2019, INCASE conference series is a platform to
              engage both the research community and the industry in
              establishing common problem statements & discuss progress in
              surface engineering technologies. The 3rd edition of the
              conference series will be organized in Singapore in September 2023
              with the theme "Surface engineering for sustainability" and aims
              to foster stronger collaboration between academics, researchers,
              and industry partners, thereby accelerating surface engineering
              technology adoption in the industry.
            </p>

            <div class="row">
              <div class="col-sm-4 py-2">
                <img
                  src="@/assets/img/landing/About the conference/1.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-sm-4 py-2">
                <img
                  src="@/assets/img/landing/About the conference/2.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-sm-4 py-2">
                <img
                  src="@/assets/img/landing/About the conference/3.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="message">
      <div class="container-fluid">
        <div class="row py-5">
          <div class="col-sm-10 mx-auto">
            <h2 class="text-center text-primary headings">
              MESSAGE FROM THE CHAIRPERSON
            </h2>
            <div class="row py-5">
              <div class="col-sm-3 text-center">
                <img
                  src="@/assets/img/landing/Speakers/Prof upadrasta.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-sm-8">
                <p class="fw-semibold text-primary mb-1 fs-4">
                  Professor Upadrasta Ramamurty
                </p>
                <p class="fw-semibold mb-0 fs-5">Conference Chairperson</p>
                <p class="fw-semibold fs-5">
                  Nanyang Technological University, Singapore
                </p>

                <p class="mb-0">
                  Encouraged by the strong support for the previous INCASE
                  (International Conference on Advanced Surface Enhancement)
                  series of conferences, we are glad to announce the third
                  edition of it, to be held at the iconic Holiday Inn Singapore
                  Atrium, Singapore from 26th to 28th September 2023. As the
                  conference Chair, I extend a warm welcome and cordial
                  invitation to you to participate in it.​
                </p>
                <p class="mb-0">
                  The key theme for INCASE 2023 is “Surface Engineering for
                  Sustainability”. As green technologies have emerged as the key
                  to addressing climate change and reducing carbon footprint
                  while ensuring socio-economic prosperity and a healthy
                  environment, there have been advancements towards
                  sustainability in surface engineering field as well to tackle
                  the global issues. The aim of this conference is to identify
                  major trends and R&D developments in surface engineering
                  community and facilitate sharing of ideas as well as
                  industrial needs and applications. ​
                </p>
                <p class="mb-0">
                  The key topics for the conference range from traditional
                  surface engineering methods to advanced modeling and new
                  materials. The topics for the conference cover recent
                  developments in the field of peening, coatings, laser
                  processing, advanced material developments, computational
                  modelling, non-destructive testing, and characterization. In
                  addition, surface engineering for emerging trends will also be
                  a key topic. Through focused industry discussion sessions, the
                  conference will also provide a platform to brainstorm on
                  current and future trends and needs of the field. ​
                </p>
                <p class="mb-0">
                  We envision the conference to be a vibrant “melt-pool” for
                  sharing innovative solutions and a platform for connecting
                  with like-minded experts in the field. The main objective of
                  INCASE 2023 is to encourage academics and practitioners to
                  meet and exchange ideas in a conducive environment for
                  stimulating discussions. It will offer an exceptional
                  opportunity for renewing old acquaintances, making new
                  friends, and enabling partnerships across borders. We invite
                  the surface engineering community to join us in this exciting
                  forum to share their outstanding contributions to the field.​
                </p>
                <p class="mb-0">
                  Travelling to Singapore is a guaranteed diverse experience.
                  The thriving city has blended Chinese, Malay, Indian, Arabic
                  and Eurasian cultures, religions, and influences over the past
                  centuries. This unique ethnic tapestry offers you a wide array
                  of activities during your stay in Singapore.​
                </p>
                <p class="mb-0">
                  We look forward to seeing you in Singapore for what promises
                  to be a great event!​
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="key-speakers">
      <div class="container-fluid py-3" style="background: #eee">
        <div class="row py-5">
          <div class="col-sm-10 mx-auto">
            <h2 class="text-center text-primary headings">KEY SPEAKERS</h2>

            <div class="row py-5">
              <div class="col-sm-5 mx-auto">
                <div class="d-flex justify-content-center ">
                  <div class="text-center me-3">
                    <img
                      src="@/assets/img/landing/Speakers/GOH.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div >
                    <p class="fs-3 fw-bold mb-0">Guest-of-Honour</p>
                    <p class="fs-4 fw-bold text-primary mb-0">
                      Professor Ric Parker
                    </p>
                    <p class="mb-0">Chairman,</p>
                    <p class="mb-0">Aerospace Programme</p>
                    <p class="mb-0">A*STAR, Singapore</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 my-4">
                <div class="row">
                  <div class="col-sm-4 text-center">
                    <img
                      src="@/assets/img/landing/Speakers/Dr ing thomas haubold.png"
                      class="img-fluid mb-2"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-12">
                    <p class="speaker-name fw-bold text-primary mb-0">
                      Dr. Ing. Thomas Haubold
                    </p>
                    <p class="mb-0 speaker-det">Engineering Fellow</p>
                    <p class="mb-0 speaker-det">Global Process Owner,</p>
                    <p class="mb-0 speaker-det">Surface Modification</p>
                    <p class="mb-0 speaker-det">Rolls-Royce</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 my-4 ">
                <div class="row">
                  <div class="col-sm-4 text-center">
                    <img
                      src="@/assets/img/landing/Speakers/Christopher.png"
                      class="img-fluid mb-2"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-12">
                    <p class="speaker-name fw-bold text-primary mb-0">
                      Professor Christopher A. Schuh
                    </p>
                    <p class="mb-0 speaker-det">Professor of Metallurgy,</p>
                    <p class="mb-0 speaker-det">
                      Department of Materials Science and Engineering
                    </p>
                    <p class="mb-0 speaker-det">Massachusetts Institute of Technology</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 my-4 ">
                <div class="row">
                  <div class="col-sm-4 text-center">
                    <img
                      src="@/assets/img/landing/Speakers/Verma.png"
                      class="img-fluid mb-2"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-12">
                    <p class="speaker-name fw-bold text-primary mb-0">
                      Dr. Arpana Verma
                    </p>
                    <p class="mb-0 speaker-det">Global Manager, Materials & Innovation</p>
                    <p class="mb-0 speaker-det">Halliburton</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="conference">
      <div class="container-fluid">
        <div class="row py-5">
          <div class="col-sm-10 mx-auto">
            <h2 class="text-center text-primary headings text-uppercase">
              conference proceedings
            </h2>
            <div class="row">
              <div class="col-sm-8 py-3 mx-auto">
                <div class="row">
                  <div class="col-sm-3 mb-2 text-center">
                    <img
                      src="@/assets/img/landing/Conference proceeding 1.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-9 mb-2">
                    <p class="mb-0">Lecture Notes in Mechanical Engineering​</p>
                    <p>(LNME), Springer</p>
                    <p>Indexed by Scopus​</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-8 py-3 mx-auto">
                <div class="row">
                  <div class="col-sm-3 mb-2 text-center">
                    <img
                      src="@/assets/img/landing/Conference proceeding 2.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-9 mb-2">
                    <p class="mb-0">Materials Science in Space Technology</p>
                    <p>Frontiers in Materials</p>
                    <p class="mb-0">Indexed by SCI,</p>
                    <p>IF: 3.895</p>
                    <p><i>*for selected papers only</i></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sponsor">
      <div class="container-fluid">
        <div class="row py-5">
          <div class="col-sm-10 mx-auto">
            <h2 class="text-center text-primary headings">
              SPONSORS AND EXHIBITORS
            </h2>
            <div class="row">
              <div class="col-sm-4 text-center">
                <img
                  src="@/assets/img/landing/Sponsors and exhibitor/Platinum/Platinum-sponsor.png"
                  class="img-fluid"
                  alt=""
                />
                <p class="fs-4 mb-0 text-primary text-center">Platinum</p>
                <div class="row">
                  <div
                    class="col-sm-12 d-flex flex-column justify-content-center align-items-center"
                  >
                    <a
                      href="http://www.abrasiveengineering.com.sg/"
                      target="_blank"
                      class="text-decoration-none"
                    >
                      <img
                        src="@/assets/img/landing/Sponsors and exhibitor/Platinum/abrasive-engineering.png"
                        class="img-fluid"
                        alt="abrasive-engineering"
                      />
                    </a>
                    <a
                      href="https://www.halliburton.com/"
                      target="_blank"
                      class="text-decoration-none halliburton-logo"
                    >
                      <img
                        src="@/assets/img/landing/Sponsors and exhibitor/Platinum/halliburton-logo.png"
                        class="img-fluid"
                        alt="Halliburton"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 text-center">
                <img
                  src="@/assets/img/landing/Sponsors and exhibitor/gold sponsor/gold.png"
                  class="img-fluid"
                  alt=""
                />

                <p class="fs-4 mb-0 text-primary text-center">Gold</p>

                <div class="row">
                  <div class="col-sm-12 d-flex flex-column justify-content-center align-items-center">
                    <a
                      href="http://www.precisionlasersolutions.com/"
                      target="_blank"
                      class="text-decoration-none"
                    >
                      <img
                        src="@/assets/img/landing/Sponsors and exhibitor/gold sponsor/LPS.png"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <img
                        src="@/assets/img/landing/Sponsors and exhibitor/gold sponsor/2.png"
                        class=" img-fluid"
                        alt=""
                      />
                  </div>
                </div>
              </div>
              <div class="col-sm-4 text-center">
                <img
                  src="@/assets/img/landing/Sponsors and exhibitor/Silver/Silver.png"
                  class="img-fluid"
                  alt=""
                />
                <p class="fs-4 mb-0 text-primary text-center">Silver</p>
                <div class="row justify-content-center">
                  <div class="col-12">
                    <a
                      href="https://www.micro-airtech.com.sg/"
                      target="_blank"
                      class="text-decoration-none"
                    >
                      <img
                        src="@/assets/img/landing/Sponsors and exhibitor/Silver/microair-2.png"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://tyrida-intl.com/"
                      target="_blank"
                      class="text-decoration-none"
                    >
                      <img
                        src="@/assets/img/landing/Sponsors and exhibitor/Silver/incase-logos.png"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <contact />
    <footerComp />
  </div>
</template>

<script>
import footerComp from "@/components/layouts/footer.vue";
import contact from "@/components/layouts/contactus.vue";
export default {
  name: "home",
  components: {
    footerComp,
    contact,
  },
  mounted() {
    this.globalDeviceCheck();
  },
};
</script>
<style lang="scss" scoped>
.home {
  font-family: "GothamRounded-Medium", sans-serif;
  min-height: 100vh;
  .headings {
    font-family: "GothamRounded", sans-serif;
  }
  .conference {
    background-color: rgb(221, 221, 221);
  }
  .halliburton-logo{
    width:200px;
    height: 106px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .speaker-name{
    font-size: 14px;
  }
  .speaker-det{
    font-size: 14px;
  }
}
</style>
