<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary border-bottom">
    <div class="container align-items-end">
      <span class="navbar-brand b-600">
        <a href="/">
          <img src="@/assets/img/logo.png" class="img-fluid" alt="logo" />
        </a>
      </span>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item px-2">
            <router-link to="/" exact class="nav-link">Home</router-link>
          </li>
          <li class="nav-item px-2">
            <router-link to="/abstract" class="nav-link">
              ABSTRACT SUBMISSION
            </router-link>
          </li>

          <!-- <li class="nav-item px-2">
            <a href="/programme" class="nav-link"> Programme </a>
          </li> -->
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              href="javascript:void(0)"
              @click="reroute('/programme')"
              >Programme</a
            >
            <ul class="dropdown-menu drop-bg">
              <li class="border-bottom border-primary">
                <a
                  class="dropdown-item text-primary fw-semibold w-100 d-flex justify-content-between"
                  href="/programme#speaker"
                  ><span>SPEAKERS</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#1f3484"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </span>
                </a>
              </li>
              <li class="border-bottom border-primary">
                <a
                  class="dropdown-item text-primary fw-semibold w-100 d-flex justify-content-between"
                  href="/programme#programme"
                  ><span>PROGRAMME</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#1f3484"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg> </span
                ></a>
              </li>
              <li>
                <a
                  class="dropdown-item text-primary fw-semibold w-100 d-flex justify-content-between"
                  href="/programme#committee"
                  ><span>COMMITTEE</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#1f3484"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg> </span
                ></a>
              </li>
            </ul>
          </li>
         <li class="nav-item px-2">
            <router-link to="/register" class="nav-link">
              REGISTRATION
            </router-link>
          </li>
          <!-- <li class="nav-item px-2">
            <a href="/sponsor" class="nav-link"> SPONSORS & EXHIBITION </a>
          </li> -->
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              href="javascript:void(0)"
              @click="reroute('/sponsor')"
              >SPONSORS & EXHIBITORS</a
            >
            <ul class="dropdown-menu drop-bg">
              <li class="border-bottom border-primary">
                <a
                  class="dropdown-item text-primary fw-semibold w-100 d-flex justify-content-between text-uppercase"
                  href="/sponsor#sponsors"
                  ><span>sponsors</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#1f3484"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </span>
                </a>
              </li>
              <li class="border-bottom border-primary">
                <a
                  class="dropdown-item text-primary fw-semibold w-100 d-flex justify-content-between text-uppercase"
                  href="/sponsor#exhibitors"
                  ><span>exhibitors</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#1f3484"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg> </span
                ></a>
              </li>
              <li>
                <a
                  class="dropdown-item text-primary fw-semibold w-100 d-flex justify-content-between text-uppercase"
                  href="/sponsor#package"
                  ><span>package details</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#1f3484"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg> </span
                ></a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "navbar",

  methods: {
    reroute(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style>
.drop-bg {
  background: #d0d2ce !important;
}
</style>