<template>
  <section class="sponsors py-5">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 mb-3 mb-sm-0">
          <h3 class="h5 mb-3">Organized by</h3>
          <ol>
            <li>A*STAR Advanced Remanufacturing and Technology Centre</li>
            <li>A*STAR Singapore Institute of Manufacturing Technology</li>
          </ol>
        </div>
        <div class="col-sm-6">
          <h3 class="h5 mb-3">In partnership with</h3>
          <ol>
            <!-- <li>CoatNet Singapore</li> -->
            <li>Coventry University</li>
            <li>MFN Metal Finishing News</li>
            <li>Nanyang Technological University Singapore</li>
          </ol>
          <!-- <div class="row justify-content-around align-items-center mb-3">
            <div class="col-6 col-sm-4">
              <img
                src="@/assets/img/landing/sponsors/MFN.png"
                class="img-fluid"
                alt="MFN"
              />
            </div>
            <div class="col-6 col-sm-4">
              <img
                src="@/assets/img/landing/sponsors/NTU.png"
                class="img-fluid"
                alt="NTU"
              />
            </div>
          </div>
          <div class="row justify-content-around align-items-center">
            <div class="col-6 col-sm-4">
              <img
                src="@/assets/img/landing/sponsors/COATNET.png"
                class="img-fluid"
                alt="coatnet"
              />
            </div>
            <div class="col-6 col-sm-4">
              <img
                src="@/assets/img/landing/sponsors/Coventry-university.png"
                class="img-fluid"
                alt="Coventry-university"
              />
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "footer",
};
</script>

<style></style>
