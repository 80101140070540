import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/abstract",
    name: "abstract",
    component: () => import("../views/abstract.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/auth/register.vue"),
  },
  {
    path: "/programme",
    name: "programme",
    component: () => import("../views/programme.vue"),
  },
  {
    path: "/sponsor",
    name: "sponsor",
    component: () => import("../views/sponsor.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    component: () => import("../views/auth/registration.vue"),
  },
  {
    path: "/success",
    name: "success",
    component: () => import("../views/success.vue"),
  },
  {
    path: "/failed",
    name: "failed",
    component: () => import("../views/failed.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
