<template>
  <section class="contactus bg-light pt-4 mb-0">
    <div class="container-lg">
      <div class="d-flex justify-content-center text-center mb-4">
        <h2
          class="
            h2
            b-600
            mx-auto
            text-primary text-uppercase
            fst-italic
            headings
          "
        >
          Contact Us
        </h2>
      </div>
      <div class="row justify-content-between mb-3">
        <div class="col-sm-5">
          <p class="text-primary h5 b-600 mb-3">Drop us a message</p>
          <div class="row">
            <div class="col-6 col-sm-6">
              <div class="mb-3">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="Name"
                />
              </div>
            </div>
            <div class="col-6 col-sm-6">
              <div class="mb-3">
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="mb-3">
                <input
                  type="text"
                  name="subject"
                  class="form-control"
                  placeholder="Subject"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <textarea
                name="message"
                cols="30"
                rows="5"
                class="form-control"
                placeholder="Message"
              ></textarea>
            </div>
          </div>
          <button
            class="
              btn btn-primary
              rounded-0
              text-white
              b-600
              text-uppercase
              mt-3
            "
          >
            Submit
          </button>
        </div>
        <div class="col-sm-5">
          <div class="mb-3">
            <p class="text-primary h5 b-600 mb-1">Conference Secretariat</p>
            <a
              href="mailto:info@incase2023.org"
              target="_blank"
              class="text-decoration-none text-dark"
            >
              info@incase2023.org
            </a>
          </div>
          <p>
            <span class="text-primary h5 b-600">Venue:</span> Holiday Inn
            Singapore Atrium
          </p>
          <div class="mapouter">
            <div class="gmap_canvas border border-white border-4 p-2">
              <iframe
                width="100%"
                height="300"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Holiday%20Inn%20Singapore%20Atrium,%20an%20IHG%20Hotel&t=&z=15&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <img
            src="@/assets/img/abstract/footer-bg.png"
            class="img-fluid"
            alt="bg image"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>